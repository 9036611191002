/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const cancelStripeSubscription = /* GraphQL */ `
  mutation CancelStripeSubscription {
    cancelStripeSubscription {
      default_language
      email
      firstName
      lastName
      membership
      membershipStatus
      minutes_per_day
      native_language
      renewalDate
      startDate
      stripeId
      subscriptionID
      user_id
      user_preferences
      username
    }
  }
`;
export const createStripeCheckoutSession = /* GraphQL */ `
  mutation CreateStripeCheckoutSession($customerID: String, $price: String) {
    createStripeCheckoutSession(customerID: $customerID, price: $price) {
      clientSecret
      subscriptionId
    }
  }
`;
export const createStripeCustomer = /* GraphQL */ `
  mutation CreateStripeCustomer {
    createStripeCustomer
  }
`;
export const putStripeSessionId = /* GraphQL */ `
  mutation PutStripeSessionId($email: String, $sessionId: String) {
    putStripeSessionId(email: $email, sessionId: $sessionId)
  }
`;
export const putUserDetails = /* GraphQL */ `
  mutation PutUserDetails(
    $default_language: String
    $firstName: String
    $lastName: String
    $membershipStatus: String
    $minutes_per_day: Int
    $native_language: String
    $user_preferences: [String]
    $username: String
  ) {
    putUserDetails(
      default_language: $default_language
      firstName: $firstName
      lastName: $lastName
      membershipStatus: $membershipStatus
      minutes_per_day: $minutes_per_day
      native_language: $native_language
      user_preferences: $user_preferences
      username: $username
    ) {
      default_language
      email
      firstName
      lastName
      membership
      membershipStatus
      minutes_per_day
      native_language
      renewalDate
      startDate
      stripeId
      subscriptionID
      user_id
      user_preferences
      username
    }
  }
`;
export const putUserKeyboard = /* GraphQL */ `
  mutation PutUserKeyboard($keyboardArray: String, $language: String) {
    putUserKeyboard(keyboardArray: $keyboardArray, language: $language)
  }
`;
export const putUserWordList = /* GraphQL */ `
  mutation PutUserWordList($language: String, $wordlist: [String]) {
    putUserWordList(language: $language, wordlist: $wordlist)
  }
`;
export const stripeWebhookEndpoint = /* GraphQL */ `
  mutation StripeWebhookEndpoint {
    stripeWebhookEndpoint
  }
`;
export const updateUserVocabProgress = /* GraphQL */ `
  mutation UpdateUserVocabProgress(
    $confused_with: String
    $device_type: String!
    $language: String!
    $state_change: String!
    $test_type: String!
    $time_on_screen: String!
    $time_shown: String!
    $user_answer: String!
  ) {
    updateUserVocabProgress(
      confused_with: $confused_with
      device_type: $device_type
      language: $language
      state_change: $state_change
      test_type: $test_type
      time_on_screen: $time_on_screen
      time_shown: $time_shown
      user_answer: $user_answer
    )
  }
`;
