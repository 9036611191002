import { queries } from '../graphql'
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import Auth from '@aws-amplify/auth'

export async function getPreferences() {
  try {
    const preferences = await GraphQLAPI.graphql({
      query: queries.getUserDetails,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return await preferences.data.getUserDetails
  } catch (error) {
    if (error.message === 'No current user') {
      return false
    } else {
      console.log('ELSE', error.message)
    }
  }
}

export async function checkLogin() {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch (error) {
    return false
  }
}

export const syncGetPreferences = async () => {
  const preferences = await GraphQLAPI.graphql({
    query: queries.getUserDetails,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  })
  return await preferences.data.getUserDetails
}

export const getPreferencesPromise = async () => {
  try {
    const preferences = await GraphQLAPI.graphql({
      query: queries.getUserDetails,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return preferences
  } catch (error) {
    if (error.message === 'No current user') {
      return false
    } else {
      console.log('ELSE', error.message)
    }
  }
}
