import { enToLocaleTranslator } from './Utils'

export const supportedLanguages = [
  'arabic',
  'chinese',
  'english',
  'french',
  'german',
  'italian',
  'japanese',
  'portuguese',
  'russian',
  'spanish',
  'turkish'
].map((language) => enToLocaleTranslator(language))

export const LanguageToCode = {
  arabic: 'ar'
}

export const qwertyqwerty = {
  Escape: '',
  Digit1: '',
  Digit2: '',
  Digit3: '',
  Digit4: '',
  Digit5: '',
  Digit6: '',
  Digit7: '',
  Digit8: '',
  Digit9: '',
  Digit0: '',
  Minus: '',
  Equal: '',
  Backspace: '',
  Tab: '',
  KeyQ: '',
  KeyW: '',
  KeyE: '',
  KeyR: '',
  KeyT: '',
  KeyY: '',
  KeyU: '',
  KeyI: '',
  KeyO: '',
  KeyP: '',
  BracketLeft: '',
  BracketRight: '',
  Enter: '',
  ControlLeft: '',
  KeyA: '',
  KeyS: '',
  KeyD: '',
  KeyF: '',
  KeyG: '',
  KeyH: '',
  KeyJ: '',
  KeyK: '',
  KeyL: '',
  Semicolon: '',
  Quote: '',
  Backquote: '',
  ShiftLeft: '',
  Backslash: '',
  KeyZ: '',
  KeyX: '',
  KeyC: '',
  KeyV: '',
  KeyB: '',
  KeyN: '',
  KeyM: '',
  Comma: '',
  Period: '',
  Slash: '',
  ShiftRight: '',
  NumpadMultiply: '',
  AltLeft: '',
  Space: '',
  CapsLock: '',
  F1: '',
  F2: '',
  F3: '',
  F4: '',
  F5: '',
  F6: '',
  F7: '',
  F8: '',
  F9: '',
  F10: '',
  Pause: '',
  ScrollLock: '',
  Numpad7: '',
  Numpad8: '',
  Numpad9: '',
  NumpadSubtract: '',
  Numpad4: '',
  Numpad5: '',
  Numpad6: '',
  NumpadAdd: '',
  Numpad1: '',
  Numpad2: '',
  Numpad3: '',
  Numpad0: '',
  NumpadDecimal: ''
}

export const qwertyarabic = {
  Digit1: '١',
  Digit2: '٢',
  Digit3: '٣',
  Digit4: '٤',
  Digit5: '٥',
  Digit6: '٦',
  Digit7: '٧',
  Digit8: '٨',
  Digit9: '٩',
  Digit0: '٠',
  Minus: '-',
  Equal: '=',
  KeyQ: 'ض',
  KeyW: 'ص',
  KeyE: 'ث',
  KeyR: 'ق',
  KeyT: 'ف',
  KeyY: 'غ',
  KeyU: 'ع',
  KeyI: 'ه',
  KeyO: 'خ',
  KeyP: 'ح',
  BracketLeft: 'ج',
  BracketRight: 'ة',
  KeyA: 'ش',
  KeyS: 'س',
  KeyD: 'ي',
  KeyF: 'ب',
  KeyG: 'ل',
  KeyH: 'ا',
  KeyJ: 'ت',
  KeyK: 'ن',
  KeyL: 'م',
  Semicolon: 'ك',
  Quote: '؛',
  Backquote: 'ـ',
  Backslash: '\\',
  KeyZ: 'ظ',
  KeyX: 'ط',
  KeyC: 'ذ',
  KeyV: 'د',
  KeyB: 'ز',
  KeyN: 'ر',
  KeyM: 'و',
  Comma: '،',
  Period: '.',
  Slash: '/'
}

export const localeToCurrency = {
  'en-gb': 'GBP',
  'en-us': 'USD'
}

export const speechRecognition = {
  english: '/ws/english',
  arabic: '/ws/arabic'
}
