/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { isLocalhost } from './Common/Utils'

export const cognitoConfig = {
  Auth: {
    identityPoolId: 'eu-west-2:744b004c-7267-49c3-89d4-fb4941311d34',
    region: 'eu-west-2',
    mandatorySignIn: false
  },
  aws_project_region: 'eu-west-2',
  // identityPoolId: 'eu-west-2:744b004c-7267-49c3-89d4-fb4941311d34',
  aws_appsync_graphqlEndpoint:
    'https://2xpd5igpmnbp3i3o27bnnrzhue.appsync-api.eu-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  oauth: {
    domain: 'auth.hexapla.io',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: isLocalhost
      ? 'http://localhost:3000/app'
      : 'https://hexapla.io/app',
    redirectSignOut: isLocalhost
      ? 'http://localhost:3000/app'
      : 'https://hexapla.io/',
    responseType: 'code'
  },
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_X7oEjFcGr',
  aws_user_pools_web_client_id: '4tl1s8hsastcramstmqbqtr1ko'
  // Analytics: {
  //   disabled: false,
  //   AWSPinpoint: {
  //     applicationId: '3d24254e9f4c491fb4a3a6d2062c92a6',
  //     region: 'eu-west-2',
  //     mandatorySignIn: false
  //   }
  // }
}

export const analyticsConfig = {
  AWSPinpoint: {
    appId: '3d24254e9f4c491fb4a3a6d2062c92a6',
    region: 'eu-west-2',
    mandatorySignIn: false
  }
}

// IdentityPoolId: 'eu-west-2:744b004c-7267-49c3-89d4-fb4941311d34',
