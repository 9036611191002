/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAudio = /* GraphQL */ `
  query GetAudio($input: String, $language: String!) {
    getAudio(input: $input, language: $language)
  }
`;
export const getConjugatedWord = /* GraphQL */ `
  query GetConjugatedWord($base64EncodedString: String!, $language: String!) {
    getConjugatedWord(
      base64EncodedString: $base64EncodedString
      language: $language
    ) {
      conjugatedWord
      settings {
        gender
        number
        person
        tense
        type
      }
    }
  }
`;
export const getStripeCheckoutSession = /* GraphQL */ `
  query GetStripeCheckoutSession($sessionId: String) {
    getStripeCheckoutSession(sessionId: $sessionId)
  }
`;
export const getStripeSubscriptionOptions = /* GraphQL */ `
  query GetStripeSubscriptionOptions(
    $currency: String
    $product_ids: [String]
  ) {
    getStripeSubscriptionOptions(
      currency: $currency
      product_ids: $product_ids
    ) {
      billing_scheme
      currency
      id
      lookup_key
      metadata
      nickname
      object
      product
      recurring {
        aggregate_usage
        interval
        interval_count
        trial_period_days
        usage_type
      }
      tiers_mode
      transform_quantity
      type
      unit_amount
      unit_amount_decimal
    }
  }
`;
export const getTTS = /* GraphQL */ `
  query GetTTS($input: String, $language: String) {
    getTTS(input: $input, language: $language) {
      encoded_audio
    }
  }
`;
export const getUserDetails = /* GraphQL */ `
  query GetUserDetails {
    getUserDetails {
      default_language
      email
      firstName
      lastName
      membership
      membershipStatus
      minutes_per_day
      native_language
      renewalDate
      startDate
      stripeId
      subscriptionID
      user_id
      user_preferences
      username
    }
  }
`;
export const getUserLearningActionSummary = /* GraphQL */ `
  query GetUserLearningActionSummary {
    getUserLearningActionSummary
  }
`;
export const getUserLearningHistory = /* GraphQL */ `
  query GetUserLearningHistory {
    getUserLearningHistory {
      false_response_time
      history_24_hours {
        action_result
        device
        time
        time_shown
        username
        word_id
      }
      history_30_days {
        action_result
        device
        time
        time_shown
        username
        word_id
      }
      history_48_hours {
        action_result
        device
        time
        time_shown
        username
        word_id
      }
      history_7_days {
        action_result
        device
        time
        time_shown
        username
        word_id
      }
      true_response_time
    }
  }
`;
export const getUserVocabProgress = /* GraphQL */ `
  query GetUserVocabProgress {
    getUserVocabProgress {
      cognito_sub
      frequency
      id
      knowledge
      original
      repetition_number
      translation
    }
  }
`;
export const getUserWordLists = /* GraphQL */ `
  query GetUserWordLists {
    getUserWordLists {
      language
      owner
      visibility
      words {
        foreignWord
        partOfSpeech
        translation
      }
    }
  }
`;
export const getWordBatch = /* GraphQL */ `
  query GetWordBatch($language: String!, $lower_range: Int, $upper_range: Int) {
    getWordBatch(
      language: $language
      lower_range: $lower_range
      upper_range: $upper_range
    ) {
      audio
      frequency
      original_audio
      word {
        foreignWord
        partOfSpeech
        translation
      }
    }
  }
`;
