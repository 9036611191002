import { createTheme } from '@mui/material/styles'

// #94C973 << alterantive green
export const THEME = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeightRegular: 500
  },
  palette: {
    backgroundColour: {
      default: '#fdfffa'
    },
    mode: 'light',
    primary: {
      main: '#16171a'
    },
    info: {
      main: '#16171a'
    },
    error: {
      main: '#e7625f'
    },
    success: {
      main: '#76B947'
    }
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        // Name of the slot
        icon: {
          position: 'relative',
          paddingLeft: '0 !important',
          marginLeft: '-22px !important'
        }
      }
    },
    // Name of the component
    MuiLink: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: '#16171a'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#fdfffa'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#fdfffa'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'lowercase'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          ':first-letter': {
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#16171a'
        }
      }
    }
  }
})
