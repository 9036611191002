import {
  Typography,
  Container,
  Icon,
  CircularProgress,
  Box
} from '@mui/material'
import {
  Check as CheckIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Close as CloseIcon,
  MoreHoriz as MoreHorizIcon
} from '@mui/icons-material'
import { useState, useMemo, useEffect, useContext, useCallback } from 'react'
import { MediaRecorder, register } from 'extendable-media-recorder'
import { connect, disconnect } from 'extendable-media-recorder-wav-encoder'
import { useElapsedTime } from 'use-elapsed-time'
import levenshtein from 'talisman/metrics/levenshtein'
import { SocketContext, useSocket } from '../Socket/Socket'
import useWebSocket, { ReadyState } from 'react-use-websocket'

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

const SplitDots = () => {
  return (
    <svg id="dots" viewBox="0 0 132 58" version="1.1">
      <defs></defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="dots" fill="#A3A3A3">
          <circle id="dot1" cx="25" cy="30" r="13"></circle>
          <circle id="dot2" cx="65" cy="30" r="13"></circle>
          <circle id="dot3" cx="105" cy="30" r="13"></circle>
        </g>
      </g>
    </svg>
  )
}

const PhoenticDescription = () => {
  return <Container></Container>
}

const recordAudio = (selectedLanguage, handleRecognition) => {
  return new Promise((resolve) => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const options = {
        mimeType: 'audio/wav'
      }
      const mediaRecorder = new MediaRecorder(stream, options)
      const audioChunks = []
      mediaRecorder.addEventListener('dataavailable', (event) => {
        audioChunks.push(event.data)
        const reader = new FileReader()
        reader.readAsDataURL(event.data)
        reader.onloadend = function () {
          const base64String = reader.result
          const justTheString = base64String.substr(
            base64String.indexOf(', ') + 1
          )
          const url = 'http://127.0.0.1:3003/arabic'
          const options = {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({
              base64: justTheString
            })
          }
          fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
              handleRecognition({ recognised: data.recognised })
            })
          // ws.send(justTheString)
          // ws.emit('arabic', { data: justTheString })
        }
      })

      const start = () => {
        mediaRecorder.start()
      }

      const stop = () => {
        return new Promise((resolve) => {
          mediaRecorder.addEventListener('stop', () => {
            const audioBlob = new Blob(audioChunks)
            const audioUrl = URL.createObjectURL(audioBlob)
            const audio = new Audio(audioUrl)
            const play = () => {
              // audio.play()
            }

            resolve({ audioBlob, audioUrl, play })
          })

          mediaRecorder.stop()
        })
      }

      resolve({ start, stop })
    })
  })
}

const registerConnects = async () => {
  await register(await connect()).catch((error) => {
    const errrrrr = error
    console.log('')
  })
}

function CircularProgressWithContent(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        size={0.8 * 80}
        variant="determinate"
        value={props.value}
        sx={{
          color: '#16171a'
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.content}
      </Box>
    </Box>
  )
}

const easing = (t, b, c, d) => {
  return c * ((t = t / d - 1) * t * t + 1) + b
}

const isPlaying = true
const start = 0 // count from
const end = 100 // count to
const duration = 5
const options = { isPlaying, duration }

const CountUpComponent = () => {
  const { elapsedTime } = useElapsedTime(options)
  const currentValue = easing(elapsedTime, start, end - start, duration)
  const isComplete = currentValue === 100
  return { isComplete: isComplete, value: Math.round(currentValue) }
}

export const RecordElement = (props) => {
  const { audioFile, questionWord, targetLanguage, answer } = props
  const [score, setScore] = useState(null)
  const timer = CountUpComponent()
  const numberOfAttempts = 0

  const handleRecording = async () => {
    const recorder = await recordAudio(targetLanguage, handleRecognition)
    recorder.start()

    setTimeout(async () => {
      const audio = await recorder.stop()
    }, duration * 1000)
  }

  const handleRecognition = ({ recognised }) => {
    console.log('recognised', recognised)
    if (answer) {
      setScore(levenshtein(answer, recognised))
    } else {
      setScore(levenshtein(questionWord, recognised))
    }
  }

  useEffect(() => {
    registerConnects()
    handleRecording()
    return () => {
      disconnect()
    }
  }, [])

  return (
    <Container
      className="focalPoint"
      sx={{ maxWidth: 'fit-content !important' }}
    >
      <Container className="recordingArea">
        <Container
          sx={{
            alignItems: 'center',
            borderRight: '2px solid #16171a'
          }}
        >
          <Typography
            sx={{
              fontSize: '3.3rem',
              paddingBottom: '1rem',
              paddingTop: '1rem',
              minWidth: '10rem'
            }}
          >
            {questionWord}
          </Typography>
        </Container>
        <Container className="recordingIndicatorProgress">
          <CircularProgressWithContent
            value={timer.value}
            isComplete={timer.isComplete}
            content={
              <Icon
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                sx={
                  timer.isComplete
                    ? {
                        width: '60% !important',
                        height: '60% !important'
                      }
                    : timer.isComplete && score > 75
                    ? {
                        width: '100% !important',
                        height: '100% !important',
                        fontSize: 'unset'
                      }
                    : timer.isComplete && score < 75
                    ? {
                        width: '100% !important',
                        height: '100% !important',
                        fontSize: 'unset'
                      }
                    : {
                        width: '60% !important',
                        height: '60% !important',
                        fontSize: 'unset'
                      }
                }
              >
                {!timer.isComplete ? (
                  <RecordVoiceOverIcon
                    sx={{
                      color: '#079a07',
                      width: '80%',
                      height: '80%',
                      fontSize: 'unset'
                    }}
                  />
                ) : timer.isComplete && !score ? (
                  <SplitDots />
                ) : timer.isComplete && score > 75 ? (
                  <CheckIcon
                    sx={{ color: 'green', width: '100%', height: '100%' }}
                  />
                ) : timer.isComplete && score < 75 ? (
                  <CloseIcon
                    sx={{ color: 'red', width: '100%', height: '100%' }}
                  />
                ) : (
                  <></>
                )}
              </Icon>
            }
          />
        </Container>
      </Container>
      {score && score < 75 ? <PhoenticDescription /> : <></>}
    </Container>
  )
}
