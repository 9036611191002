/* eslint-disable */
import './Styling/App.css'
import { Suspense, lazy } from 'react'
import '@aws-amplify/ui-react/styles.css'
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import LoadingPage from './Common/LoadingPage'
// import { Teacher } from './TestingComponents/TeacherUtils/TeacherModel'

const HomePage = lazy(() => import('./Common/Home.js'))
const SignUp = lazy(() => import('./Authentication/SignUp.js'))
const Login = lazy(() => import('./Authentication/Login.js'))
const MoreInfo = lazy(() => import('./Common/MoreInfo.js'))
const AuthenticatedApp = lazy(() => import('./AuthenticatedApp.js'))
const Account = lazy(() => import('./Common'))
const AccountSetup = lazy(() => import('./Common/AccountSetup.js'))
const Calendar = lazy(() => import('./Learning/Calendar.js'))
const Progress = lazy(() => import('./Analytics/Progress.js'))
const Privacy = lazy(() => import('./Common/Privacy.js'))
const Checkout = lazy(() => import('./Payment/Checkout.js'))
const Teacher = lazy(() =>
  import('./TestingComponents/TeacherUtils/TeacherModel.js')
)
// const Test = lazy(() => import('./Test.js'))

const createCalendar = () => {
  const dt = new Date()
  const month = dt.getMonth()
  const monthName = dt.toLocaleString('default', { month: 'long' })
  const year = dt.getFullYear()
  const daysInMonth = new Date(year, month, 0).getDate()
  const currentDate = dt.getDate()
  return [daysInMonth, monthName, currentDate]
}

// async function isLoggedIn() {
//   try {
//     await Auth.currentAuthenticatedUser()
//     return true
//   } catch (error) {
//     return false
//   }
// }

// const ProtectedRoute = ({ user, children }) => {
//   if (user.username) {
//     return <Navigate to="/login" />
//   }
//   return children
// }

export const RouteManager = ({ user, setUser }) => {
  const dateInfo = createCalendar()

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path="/" element={<HomePage user={user} />} />
          <Route path="/login" element={<Login user={user} />} />
          <Route path="/about" element={<MoreInfo user={user} />} />
          <Route
            path="/signup"
            element={<SignUp user={user} setUser={setUser} />}
          />
          <Route
            path="/signout"
            element={<SignUp user={user} setUser={setUser} />}
          />
          <Route path="/app" element={<AuthenticatedApp user={user} />} />
          <Route path="/account" element={<Account user={user} />} />
          <Route path="/setup" element={<AccountSetup />} />
          <Route path="/calendar" element={<Calendar dateInfo={dateInfo} />} />
          <Route path="/progress" element={<Progress user={user} />} />
          <Route path="/privacy" element={<Progress user={user} />} />
          <Route path="/subscribe" element={<Checkout />} />
          <Route path="/teacher" element={<Teacher />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
