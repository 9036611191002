import React from 'react'
import ReactDOM from 'react-dom'
import './Styling/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@mui/material/styles'
import { THEME } from './Styling/theme'
import GlobalStyles from '@mui/material/GlobalStyles'
import { UserProvider } from './Preferences/PreferencesProvider'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import serviceWorker from './service-worker'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <UserProvider>
        <GlobalStyles
          styles={{
            body: { backgroundColor: '#fdfffa' }
          }}
        />
        <App />
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
serviceWorkerRegistration.register()
// serviceWorker.enablePush('BLx__NGvdasMNkjd6VYPdzQJVBkb2qafh')
