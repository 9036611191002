import { mutations, queries } from '../graphql'
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'

export const getStripeSubscriptionOptions = async (currency, type) => {
  const response = await GraphQLAPI.graphql({
    query: queries.getStripeSubscriptionOptions,
    variables: { currency: currency.toLowerCase(), type: type },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  })

  return response
}

export const createStripeCheckoutSession = async (priceID) => {
  const customerDetails = await GraphQLAPI.graphql({
    query: mutations.createStripeCheckoutSession,
    variables: { price: priceID },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  })
  return customerDetails
}

// export const putStripeSessionId = () => {

// }

// export const createStripeCheckoutSession = (priceID) => {
//     const customerDetails = client.query({
//         query: gql(queries.getUserDetails),
//         fetchPolicy: "network-only"
//     })
//     .then((data) => {
//     })

//     return customerDetails
// }

// export const getStripeCheckoutSession = () => {

// }

// export const cancelStripeSubscription = () => {
//     const cancelSubscription = client.mutate({
//         mutation: gql(mutations.cancelStripeSubscription),
//         fetchPolicy: "no-cache"
//     })
// }
