import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Typography,
  Link,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

export const HelpMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title="help">
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{ color: 'var(--main-colour)' }}
        >
          <HelpOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => window.Usersnap.logEvent('supportButtonClicked')}
        >
          <Link underline="none">support</Link>
        </MenuItem>
        <MenuItem
          onClick={() => window.Usersnap.logEvent('featureButtonClicked')}
        >
          <Link underline="none">suggest a feature</Link>
        </MenuItem>
        <MenuItem onClick={() => window.Usersnap.logEvent('bugReportClicked')}>
          <Link underline="none">report a bug</Link>
        </MenuItem>
      </Menu>
    </>
  )
}

export const Header = () => {
  async function handleSignOut() {
    try {
      await Auth.signOut()
      Hub.dispatch('UI Auth', {
        event: 'AuthStateChange',
        message: 'signedout'
      })
      window.location.reload()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div className="hexaplaHeaderLogo">
            <Link href="/" underline="none">
              <Typography
                className="header-hexapla unselectable"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                hexapla
              </Typography>
            </Link>
          </div>
          <div className="headerActionButton">
            <HelpMenu />
            <Button
              sx={{
                textTransform: 'lowercase !important',
                fontWeight: 'bold'
              }}
              color="inherit"
              component={RouterLink}
              to="/app"
            >
              learn
            </Button>
            <Button
              sx={{
                textTransform: 'lowercase !important',
                fontWeight: 'bold'
              }}
              color="inherit"
              component={RouterLink}
              to="/progress"
            >
              progress
            </Button>
            <Button
              sx={{
                textTransform: 'lowercase !important',
                fontWeight: 'bold'
              }}
              color="inherit"
              component={RouterLink}
              to="/account"
            >
              account
            </Button>
            <Button
              sx={{
                textTransform: 'lowercase !important',
                fontWeight: 'bold'
              }}
              color="inherit"
              onClick={handleSignOut}
            >
              logout
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

const SignedOutHeader = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div className="hexaplaHeaderLogo">
            <Link href="/" underline="none">
              <Typography
                className="header-hexapla unselectable"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                hexapla
              </Typography>
            </Link>
          </div>
          <div className="headerActionButton">
            <HelpMenu />
            <Button
              sx={{
                textTransform: 'lowercase',
                fontWeight: 'bold'
              }}
              component={RouterLink}
              to="/login"
              color="inherit"
            >
              Login/SignUp
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default SignedOutHeader
