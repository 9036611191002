import React from 'react'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'auth':
      return action.payload
    default:
      return state
  }
}

export const initialState = {
  // this is a comment
  active: false
}

export const UserContext = React.createContext({
  state: initialState,
  dispatch: () => null
})

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  )
}
