import React, { useState, useEffect, useRef } from 'react'
import { IconButton, Container } from '@mui/material'
import { VolumeUp as VolumeUpIcon } from '@mui/icons-material'

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export function PlayAudio(audio) {
  const languageAudio = new Audio(audio)
  languageAudio.play()
}

export const PlayAudioElement = (props) => {
  const { audio } = props

  const firstLoad = useRef(true)

  const languageAudio = new Audio(audio)

  const playSound = () => {
    if (languageAudio !== undefined) {
      languageAudio.play()
    }
  }

  useEffect(() => {
    if (firstLoad.current) {
      playSound()
      firstLoad.current = false
    }
  }, [audio])

  return (
    <Container>
      {languageAudio ? (
        <IconButton aria-label="speak" onClick={playSound}>
          <VolumeUpIcon className="playAudioIcon" />
        </IconButton>
      ) : (
        <IconButton disabled aria-label="speak" onClick={playSound}>
          <VolumeUpIcon className="playAudioIcon" />
        </IconButton>
      )}
    </Container>
  )
}

export function debounce(func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function wrapPromise(promise) {
  let status = 'pending'
  let result
  const suspender = promise.then(
    (r) => {
      status = 'fulfilled'
      result = r
    },
    (e) => {
      status = 'error'
      result = false
    }
  )
  return {
    read() {
      if (status === 'pending') {
        throw suspender
      } else if (status === 'error') {
        throw result
      } else if (status === 'fulfilled') {
        return result
      }
    }
  }
}

export const enToLocaleTranslator = (input) => {
  return input
}

export function promiseDelay(ms) {
  return function (x) {
    return new Promise((resolve) => setTimeout(() => resolve(x), ms))
  }
}

export const DetectMobile = () => {
  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  return isMobile
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export function shuffleArray(array) {
  const cloneArray = JSON.parse(JSON.stringify(array))
  let currentIndex = array.length
  let randomIndex

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[cloneArray[currentIndex], cloneArray[randomIndex]] = [
      cloneArray[randomIndex],
      cloneArray[currentIndex]
    ]
  }

  return cloneArray
}

function splitAndRandomise(
  primaryTrueValue,
  originalArray,
  numberOfAnswersPerGroup
) {
  const valueToEdit = Object.assign({}, primaryTrueValue)
  const filterOutTrueValue = originalArray.filter(
    (word) => word.id !== primaryTrueValue.id
  )
  const shuffledList = shuffleArray(filterOutTrueValue)
  const selected = shuffledList.slice(0, numberOfAnswersPerGroup - 1)
  valueToEdit.isanswer = true
  const joinedArray = selected.concat(valueToEdit)
  return joinedArray
}

export const formatDBToTesting = (inputData, range) => {
  const formatted = inputData.map((dbFormat) => ({
    id: dbFormat.frequency,
    original: dbFormat.word.foreignWord,
    translation: dbFormat.word.translation,
    audio: dbFormat.audio,
    original_audio: dbFormat.original_audio,
    isanswer: false
  }))

  const batchedAnswers = []
  for (const word of formatted) {
    const temp = splitAndRandomise(word, formatted, 6)
    batchedAnswers.push(temp)
  }
  // const shuffledFinalArray = shuffleArray(batchedAnswers)
  return batchedAnswers
}
