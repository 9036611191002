import { useState, useEffect } from 'react'
import {
  Container,
  Typography,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress
} from '@mui/material'
import { mutations } from '../graphql'
import { checkLogin } from '../Preferences/GetPreferences'
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import { supportedLanguages } from './Consts'
import Auth from '@aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import SignedOutHeader, { Header } from './Header'

export const AccountSetup = ({ user }) => {
  const navigate = useNavigate()
  const [learnBefore, setLearnBefore] = useState('')
  const [nativeLanguage, setNativeLanguage] = useState('')
  const [learningLanguage, setLearningLanguage] = useState('')
  const [whyLearning, setWhyLearning] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false)
  const disableSubmit =
    learnBefore.length > 0 &&
    nativeLanguage.length > 0 &&
    learningLanguage.length > 0 &&
    whyLearning.length > 0

  const [numberOfMinutesPerDay, setNumberOfMinutesPerDay] = useState()

  const updatePreferences = () => {
    setIsSubmitting(true)
    const updateInformation = {
      default_language: learningLanguage,
      native_language: nativeLanguage,
      minutes_per_day: numberOfMinutesPerDay,
      user_preferences: JSON.stringify({
        learnBefore: learnBefore,
        whyLearning: whyLearning
      })
    }
    Auth.currentSession().then((data) => {
      console.log('CSS', data)
    })
    console.log(updateInformation)
    GraphQLAPI.graphql({
      query: mutations.putUserDetails,
      variables: updateInformation,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
      .then((data) => {
        console.log('data', data)
        navigate('/app')
      })
      .catch((error) => {
        console.log('err', error)
      })
  }

  useEffect(() => {
    const checkAuth = async () => {
      const result = await checkLogin()
      if (!result) {
        navigate('/login')
      }
    }
    checkAuth()
  }, [])

  return (
    <>
      {user && user.username ? <Header /> : <SignedOutHeader />}
      <Container className="focalPoint">
        <Typography sx={{ fontWeight: 'bold' }} variant="h5">
          let&apos;s setup your learning preferences
        </Typography>
        <Container className="userPreferencesFormContainer">
          <FormGroup className="userPreferencesForm">
            <FormControl required sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="native-language-label">
                what is your native language?
              </InputLabel>
              <Select
                labelId="native-language-label"
                id="native-language"
                value={nativeLanguage}
                label="what is your native language?"
                onChange={(event) => setNativeLanguage(event.target.value)}
                sx={{
                  color: 'black'
                }}
              >
                {supportedLanguages
                  .filter((language) => language !== learningLanguage)
                  .map((language) => {
                    return (
                      <MenuItem key={language} value={language}>
                        {language}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <FormControl required sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="learning-language-label">
                what language do you want to learn?
              </InputLabel>
              <Select
                labelId="learning-language-label"
                id="learning-language"
                value={learningLanguage}
                label="what language do you want to learn?"
                onChange={(event) => setLearningLanguage(event.target.value)}
                sx={{
                  color: 'black'
                }}
              >
                {supportedLanguages
                  .filter((language) => language !== nativeLanguage)
                  .map((language) => {
                    return (
                      <MenuItem key={language} value={language}>
                        {language}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <FormControl required sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="tried-to-learn-before-label">
                have you tried to learn this language before?
              </InputLabel>
              <Select
                labelId="tried-to-learn-before-label"
                id="tried-to-learn-before"
                value={learnBefore}
                label="have you tried to learn this language before?"
                onChange={(event) => setLearnBefore(event.target.value)}
                sx={{
                  color: 'black'
                }}
              >
                <MenuItem key="1" value="No">
                  no
                </MenuItem>
                <MenuItem key="2" value="yes, just the alphabet">
                  yes, just the alphabet
                </MenuItem>
                <MenuItem key="3" value="yes, a little vocab and grammar">
                  yes, a little vocab and grammar
                </MenuItem>
                <MenuItem key="4" value="yes, I can have small conversations">
                  yes, I can have small conversations
                </MenuItem>
                <MenuItem
                  key="5"
                  value="yes, I'm fairly proficient and looking to improve"
                >
                  yes, I&apos;m fairly proficient and looking to improve
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl required sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="why-learning-label">
                is there a reason you&apos;re learning the language?
              </InputLabel>
              <Select
                labelId="why-learning-label"
                id="why-learning"
                value={whyLearning}
                label="is there a reaso you're learning the language?"
                onChange={(event) => setWhyLearning(event.target.value)}
                sx={{
                  color: 'black'
                }}
              >
                <MenuItem key="1" value="just for fun">
                  just for fun
                </MenuItem>
                <MenuItem key="2" value="for work">
                  for work
                </MenuItem>
                <MenuItem key="3" value="relationship/personal">
                  relationship/personal
                </MenuItem>
                <MenuItem key="4" value="travelling to/around the country">
                  travelling to/around the country
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl required sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="how-much-time-label">
                how many minutes do you want to learn per day?
              </InputLabel>
              <Select
                labelId="how-much-time-label"
                id="how-much-time"
                value={numberOfMinutesPerDay}
                label="how many minutes do you want to learn per day?"
                onChange={(event) =>
                  setNumberOfMinutesPerDay(event.target.value)
                }
                sx={{
                  color: 'black'
                }}
              >
                <MenuItem key="0" value={15}>
                  15
                </MenuItem>
                <MenuItem key="1" value={30}>
                  30
                </MenuItem>
                <MenuItem key="2" value={45}>
                  45
                </MenuItem>
                <MenuItem key="3" value={60}>
                  60
                </MenuItem>
              </Select>
            </FormControl>
            <div className="focalPoint">
              {!isSubmitting ? (
                <Button
                  className="mainButton"
                  onClick={updatePreferences}
                  disabled={!disableSubmit}
                  sx={
                    !disableSubmit
                      ? {
                          backgroundColor: 'rgb(22,23,26, 0.1) !important;'
                        }
                      : {}
                  }
                >
                  get started
                </Button>
              ) : (
                <CircularProgress />
              )}
            </div>
          </FormGroup>
        </Container>
      </Container>
    </>
  )
}

export default AccountSetup
