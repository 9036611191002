import {
  Modal,
  Fade,
  Box,
  Backdrop,
  Container,
  Button,
  CircularProgress
} from '@mui/material'
import { useState } from 'react'
import {
  useStripe,
  useElements,
  Elements,
  PaymentElement
} from '@stripe/react-stripe-js'

const fonts = [
  {
    src: 'url(https://fonts.googleapis.com/css2?family=Poppins)',
    family: 'Poppins',
    style: 'normal'
  },
  {
    cssSrc: 'url(https://fonts.googleapis.com/css2?family=Poppins)'
  }
]

const appearance = {
  fonts: fonts,
  variables: {
    colorPrimary: '#0570de',
    colorBackground: '#fdfffa',
    colorText: '#30313d',
    colorDanger: '#df1b41',
    fontFamily: 'Poppins, sans-serif !important',
    spacingUnit: '2px',
    borderRadius: '8px'
    // See all possible variables below
  }
}

const StripeCheckout = () => {
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(true)

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://hexapla.io'
      }
    })

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log('ASDSADAS', result.error.message)
    } else {
      console.log('ELSE ERRRO')
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <Container className="focalPoint" onSubmit={handleSubmit}>
      {isLoading ? (
        <Container className="focalPoint">
          <CircularProgress />
        </Container>
      ) : null}
      <PaymentElement
        id="payment-element"
        onReady={() => setIsLoading(false)}
      />
      <Button
        className="mainButton"
        onClick={handleSubmit}
        sx={{ margin: '2rem' }}
        disabled={!stripe}
      >
        submit
      </Button>
    </Container>
  )
}

const Checkout = ({ options, stripePromise }) => {
  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckout />
    </Elements>
  )
}

export const CheckoutForm = (props) => {
  const { open, handleOpen, clientSecret, stripePromise } = props

  const options = {
    clientSecret: clientSecret,
    appearance: appearance
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '30%',
    minHeight: '30%',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    justifyContent: 'center',
    p: 4
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => handleOpen(!open)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {options && options.clientSecret !== undefined ? (
            <Checkout options={options} stripePromise={stripePromise} />
          ) : (
            <Container className="focalPoint">
              <CircularProgress />
            </Container>
          )}
        </Box>
      </Fade>
    </Modal>
  )
}
