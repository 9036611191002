import {
  Modal,
  Fade,
  Box,
  Typography,
  Backdrop,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Grid,
  List,
  ListItem,
  CircularProgress,
  Container
} from '@mui/material'

const currencyToSymbol = {
  gbp: '£',
  usd: '$',
  eur: '€'
}

export const ChangeCancel = (props) => {
  const { open, handleOpen, cancelMembership } = props

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }

  return (
    <Modal
      aria-labelledby="change or cancel membership"
      aria-describedby="change or cancel membership"
      open={open}
      onClose={() => handleOpen(!open)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              marginBottom: '2rem',
              fontWeight: 'bold'
            }}
          >
            {' '}
            update details{' '}
          </Typography>
          <Button onClick={cancelMembership}>cancel</Button>
          {/* {
              <Grid container spacing={3}>
                  {subscriptions.map((subDetails) => {
                    return(
                      <Grid item xs>
                            <PricingCard details={subDetails} isCurrent={subDetails.isCurrent} setShowPayment={setShowPayment} setSelectedSubscription={setSelectedSubscription}/>
                          </Grid>
                      )
                  })
                  }
              </Grid>
            } */}
        </Box>
      </Fade>
    </Modal>
  )
}

const PricingCard = ({
  details,
  setShowPayment,
  setSelectedSubscription,
  setShowChangeCancel,
  description
}) => (
  <Card sx={{ margin: '1rem' }} variant="outlined">
    <CardHeader
      title={details.recurring.interval === 'year' ? 'yearly' : 'monthly'}
    />
    <Divider variant="middle" />
    <CardContent>
      <Typography variant="h4" align="center">
        {currencyToSymbol[details.currency]}
        {(parseInt(details.unit_amount) / 100).toFixed(2)}
      </Typography>
      <Typography>
        {description && description.length === 4 ? (
          <List>
            {description.map((descriptions, index) => {
              return <ListItem key={index}>{descriptions}</ListItem>
            })}
          </List>
        ) : (
          <></>
        )}
      </Typography>
    </CardContent>
    <Divider variant="middle" />
    <CardActions sx={{ justifyContent: 'center', margin: '1rem' }}>
      {!details.isCurrent ? (
        <Button
          variant="contained"
          className="mainButton"
          sx={{ textTransform: 'lowercase' }}
          onClick={() => {
            setShowPayment(true)
            setSelectedSubscription(details.id)
          }}
        >
          subscribe
        </Button>
      ) : (
        <Box sx={{ display: 'block', textAlign: 'center' }}>
          <Button
            variant="contained"
            className="mainButton"
            sx={{ textTransform: 'lowercase' }}
            onClick={() => {
              setShowChangeCancel(true)
            }}
          >
            update
          </Button>
          <Button
            variant="contained"
            className="mainButton"
            sx={{ textTransform: 'lowercase' }}
            onClick={() => {
              setShowChangeCancel(true)
            }}
          >
            cancel
          </Button>
        </Box>
      )}
    </CardActions>
  </Card>
)

const SubscriptionDescriptions = [
  [
    'no ads',
    'silver flair icon',
    'greater analytics',
    "we'll plant two extra trees"
  ],
  [
    'no ads',
    'gold flair icon',
    'greater analytics',
    "we'll plant four extra trees"
  ],
  [
    'no ads',
    'diamond flair icon',
    'greater analytics',
    "we'll plant seven extra trees"
  ]
]

export const ManageMembership = (props) => {
  const {
    open,
    handleOpen,
    subscriptions,
    customerDetails,
    setShowPayment,
    setSelectedSubscription,
    setShowChangeCancel
  } = props

  if (subscriptions) {
    subscriptions
      .sort((a, b) => parseInt(a.unit_amount) - parseInt(b.unit_amount))
      .map((subDetails) =>
        subDetails.recurring.interval === customerDetails.membership
          ? (subDetails.isCurrent = true)
          : false
      )
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '80vw',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '8px',
    backgroundColor: 'var(--main-white-colour)',
    boxShadow: 24,
    p: 4
  }

  return (
    <Modal
      aria-labelledby="choose donation amount"
      aria-describedby="manage donation amount to hexapla"
      open={open}
      onClose={() => handleOpen(!open)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              marginBottom: '2rem',
              fontWeight: 'bold'
            }}
          >
            {' '}
            want to subscribe?{' '}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'center',
              marginBottom: '1rem'
            }}
          >
            {' '}
            the app is free to use, but if you&apos;d like to remove ads and
            support future developments you can below - thank you!{' '}
          </Typography>
          {
            <Grid container spacing={3}>
              {subscriptions.map((subDetails, index) => {
                return (
                  <Grid item xs key={index}>
                    <PricingCard
                      details={subDetails}
                      isCurrent={subDetails.isCurrent}
                      setShowPayment={setShowPayment}
                      setSelectedSubscription={setSelectedSubscription}
                      setShowChangeCancel={setShowChangeCancel}
                      description={SubscriptionDescriptions[index]}
                    />
                  </Grid>
                )
              })}
            </Grid>
          }
          <Typography
            variant="h9"
            sx={{
              textAlign: 'center',
              marginBottom: '1rem'
            }}
          >
            {' '}
            we don&apos;t like forgetting recurring payments either, so if you
            setup a donation and don&apos;t use the app for more than 60 minutes
            in the month we won&apos;t charge your payment method{' '}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  )
}

export const Membership = (props) => {
  const {
    open,
    handleOpen,
    subscriptions,
    setShowPayment,
    setSelectedSubscription
  } = props

  if (subscriptions) {
    subscriptions.sort(
      (a, b) => parseInt(a.unit_amount) - parseInt(b.unit_amount)
    )
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }

  return (
    <Modal
      aria-labelledby="subscribe to hexapla"
      aria-describedby="subscribe to hexapla"
      open={open}
      onClose={() => handleOpen(!open)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              marginBottom: '2rem',
              fontWeight: 'bold'
            }}
          >
            {' '}
            pricing{' '}
          </Typography>
          {subscriptions && subscriptions.length > 0 ? (
            // !isSubscribed ?
            <Grid container spacing={3}>
              {subscriptions.map((subDetails, index) => {
                return (
                  <Grid item xs key={index}>
                    <PricingCard
                      details={subDetails}
                      setShowPayment={setShowPayment}
                      setSelectedSubscription={setSelectedSubscription}
                      description={SubscriptionDescriptions[index]}
                    />
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <Container className="focalPoint">
              <CircularProgress />
            </Container>
          )}
        </Box>
      </Fade>
    </Modal>
  )
}
