import { Navigate, useNavigate } from 'react-router-dom'
import {
  Container,
  Grid,
  Typography,
  Card,
  Icon,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  TextField,
  MenuItem,
  Button,
  Snackbar,
  Alert
} from '@mui/material'
import {
  ManageAccountsOutlined as ManageAccountsOutlinedIcon,
  CardMembership as CardMembershipIcon,
  TuneOutlined as TuneOutlinedIcon,
  Check as CheckIcon,
  Edit as EditIcon,
  Close as CancelIcon
} from '@mui/icons-material'
import { useState, useEffect, lazy } from 'react'
import { supportedLanguages } from '.'
import { mutations } from '../graphql'
import { Auth } from '@aws-amplify/auth'
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'
import * as STRIPE from './StripeFunctions'
import { localeToCurrency } from './Consts'
import { CheckoutForm } from '../Donate/Payment'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { Header } from './Header'
import {
  getPreferences,
  getPreferencesPromise,
  checkLogin
} from '../Preferences/GetPreferences'
import LoadingPage from './LoadingPage'
import { loadStripe } from '@stripe/stripe-js'
import { wrapPromise } from './Utils'
import {
  Membership as BuyMembership,
  ManageMembership,
  ChangeCancel
} from './Membership'

const resource = wrapPromise(getPreferences())

const stripePromise = loadStripe(
  'pk_live_51JF50ZKavjGDz9jG1bW1u6CSgB4Q0nXe4QqSfynUHxZnzgpSYLUeywcPLIfzreXEJiGJ6vDZDzKiuiy9TF4akyr500osMO3g6l'
)

function hasKey(object, key) {
  return object ? hasOwnProperty.call(object, key) : false
}

const ResetPassword = lazy(() => import('../Authentication/ResetPassword'))

const EditPreferenceButton = ({
  fieldEditable,
  toggleField,
  id,
  validateInput
}) =>
  !fieldEditable ? (
    <IconButton
      disabled={fieldEditable}
      className="noHoverEffect"
      onClick={() => toggleField(id)}
      id={id}
    >
      <EditIcon
        id={id}
        sx={{
          disabled: {
            color: 'red !important'
          }
        }}
      />
    </IconButton>
  ) : (
    <div style={{ display: 'block' }}>
      <IconButton
        sx={{ padding: 0, margin: 0 }}
        onClick={() => validateInput(id)}
      >
        <CheckIcon />
      </IconButton>
      <IconButton
        sx={{ padding: 0, margin: 0 }}
        onClick={() => toggleField(id)}
        id={id}
      >
        <CancelIcon id={id} />
      </IconButton>
    </div>
  )

const PreferenceGrid = ({
  learningPreferences,
  preferencesToggle,
  labels,
  options,
  newPreferences,
  handleInput,
  handleToggle,
  validateInput
}) =>
  Object.keys(learningPreferences).map((preferenceName, index) => {
    return (
      <TextField
        style={
          !preferencesToggle[preferenceName]
            ? {
                div: {
                  '[data-testid=ArrowDropDownIcon]': {
                    display: 'none'
                  }
                }
              }
            : {}
        }
        sx={{
          minWidth: '11rem',
          input: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordWrap: 'break-word',
            display: 'block',
            disabled: {
              backgroundColor: 'var(--main-white-colour)!important'
            }
          },
          label: {
            color: 'black !important'
          }
        }}
        id={preferenceName}
        InputLabelProps={{
          shrink: true
        }}
        label={labels[preferenceName]}
        select={hasKey(options, preferenceName)}
        key={index}
        value={
          Object.prototype.hasOwnProperty.call(newPreferences, preferenceName)
            ? newPreferences[preferenceName]
            : learningPreferences[preferenceName]
        }
        placeholder={learningPreferences[preferenceName]}
        disabled={!preferencesToggle[preferenceName]}
        onChange={handleInput}
        InputProps={{
          name: preferenceName,
          id: preferenceName,
          endAdornment: (
            <EditPreferenceButton
              id={preferenceName}
              fieldEditable={preferencesToggle[preferenceName]}
              toggleField={handleToggle}
              validateInput={validateInput}
            />
          )
        }}
      >
        {options[preferenceName] ? (
          options[preferenceName].map((preference, index) => {
            return (
              <MenuItem value={preference} key={index} name={preference}>
                {preference}
              </MenuItem>
            )
          })
        ) : (
          <></>
        )}
      </TextField>
    )
  })

export const Account = ({ user }) => {
  const navigate = useNavigate()
  const userPreferences = resource.read()
  const account = ['email', 'username']
  const learning = ['default_language', 'minutes_per_day', 'native_language']
  const membership = ['membership']
  const [newPreferences, setNewPreferences] = useState({})
  const [badReason, setbadReason] = useState('')
  const [whatUpdated, setwhatUpdated] = useState('')
  const [successfulUpdate, setSuccessfulUpdate] = useState(false)
  const [badUpdate, setBadUpdate] = useState(false)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [showMembershipOptions, setShowMembershipOptions] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const [subscriptionData, setSubscriptionData] = useState({})
  const [showManageMembership, setShowManageMembership] = useState(false)
  const checkEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return false
    } else {
      return true
    }
  }

  const checkUserName = (username) => {
    if (username && username.length > 3) {
      return false
    } else {
      return true
    }
  }
  const learningPreferences = Object.keys(user)
    .filter((key) => learning.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: user[key]
      }
    }, {})

  const accountPreferences = Object.keys(user)
    .filter((key) => account.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: user[key]
      }
    }, {})

  const membershipPreferences = Object.keys(user)
    .filter((key) => membership.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: user[key]
      }
    }, {})

  const [preferencesToggle, setPreferencesToggle] = useState({
    email: false,
    username: false,
    default_language: false,
    minutes_per_day: false,
    native_language: false
  })

  const handleToggle = (field) => {
    setPreferencesToggle((prevValue) => ({
      ...prevValue,
      [field]: !preferencesToggle[field]
    }))
  }

  const validateInput = () => {
    console.log('validating')
  }

  const options = {
    default_language: supportedLanguages,
    minutes_per_day: [15, 30, 45, 60],
    native_language: supportedLanguages
  }

  const labels = {
    default_language: 'what language do you want to learn?',
    minutes_per_day: 'how many minutes a day are you free to learn?',
    native_language: 'what is your native language?',
    email: 'email',
    username: 'username',
    membership: 'supporter'
  }

  const handleInput = (event) => {
    setNewPreferences((prevValue) => ({
      ...prevValue,
      [event.target.name]: event.target.value
    }))
  }

  const userLocaleCurrency =
    localeToCurrency[navigator.language.toLowerCase()] !== undefined
      ? localeToCurrency[navigator.language.toLowerCase()]
      : 'USD'

  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    STRIPE.getStripeSubscriptionOptions(
      userLocaleCurrency,
      'subscription'
    ).then((data) => {
      setSubscriptions(data.data.getStripeSubscriptionOptions)
    })
  }, [preferencesToggle, newPreferences])

  useEffect(() => {
    const checkAuth = async () => {
      const result = await checkLogin()
      if (!result) {
        navigate('/login')
      }
    }
    checkAuth()
  }, [])

  const [showPayment, setShowPayment] = useState(false)

  return (
    <>
      <Header />
      <Container className="focalPoint" style={{ marginTop: '5rem' }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Card sx={{ marginTop: '2rem', height: '100%' }} variant="string">
              <Icon fontSize="large" className="headerIconContainer">
                <ManageAccountsOutlinedIcon className="headerIcon" />
              </Icon>
              <Typography variant="h6" sx={{ margin: 0, marginBottom: '2rem' }}>
                {' '}
                your details{' '}
              </Typography>
              <Container
                sx={{
                  display: 'grid',
                  gap: '1rem',
                  justifyItems: 'stretch'
                }}
              >
                {showPasswordReset ? (
                  <ResetPassword
                    setSuccessfulUpdate={setSuccessfulUpdate}
                    setBadUpdate={setBadUpdate}
                    setwhatUpdated={setwhatUpdated}
                    setbadReason={setbadReason}
                  />
                ) : (
                  <PreferenceGrid
                    learningPreferences={accountPreferences}
                    preferencesToggle={preferencesToggle}
                    labels={labels}
                    options={options}
                    newPreferences={newPreferences}
                    handleInput={handleInput}
                    handleToggle={handleToggle}
                    validateInput={validateInput}
                  />
                )}
                <TextField
                  disabled
                  sx={{ fieldset: { border: '0px solid white' } }}
                  style={showPasswordReset ? { display: 'none' } : {}}
                />
                <Button
                  sx={{ margin: '1rem 0 2rem 0 !important' }}
                  className="mainButton"
                  onClick={() => setShowPasswordReset(!showPasswordReset)}
                >
                  {!showPasswordReset ? 'change password' : 'cancel'}
                </Button>
              </Container>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ marginTop: '2rem', height: '100%' }} variant="string">
              <Icon fontSize="large" className="headerIconContainer">
                <TuneOutlinedIcon className="headerIcon" />
              </Icon>
              <Typography variant="h6" sx={{ margin: 0, marginBottom: '2rem' }}>
                {' '}
                learning preferences{' '}
              </Typography>
              <Container
                sx={{
                  display: 'grid',
                  gap: '1rem',
                  justifyItems: 'stretch'
                }}
              >
                <PreferenceGrid
                  learningPreferences={learningPreferences}
                  preferencesToggle={preferencesToggle}
                  labels={labels}
                  options={options}
                  newPreferences={newPreferences}
                  handleInput={handleInput}
                  handleToggle={handleToggle}
                  validateInput={validateInput}
                />
              </Container>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ marginTop: '2rem', height: '100%' }} variant="string">
              <Icon fontSize="large" className="headerIconContainer">
                <CardMembershipIcon className="headerIcon" />
              </Icon>
              <Typography variant="h6" sx={{ margin: 0, marginBottom: '2rem' }}>
                {' '}
                supporter{' '}
              </Typography>
              <Container
                sx={{
                  display: 'grid',
                  gap: '1rem',
                  justifyItems: 'stretch'
                }}
              >
                <TextField
                  value={user.membership}
                  disabled
                  label="supporter"
                  InputLabelProps={{
                    shrink: true
                  }}
                  sx={{
                    input: {
                      color: 'black !important'
                    },
                    label: {
                      color: 'black !important'
                    }
                  }}
                />
                <TextField
                  disabled
                  sx={{ fieldset: { border: '0px solid white' } }}
                />
                <TextField
                  disabled
                  sx={{ fieldset: { border: '0px solid white' } }}
                />
                <Button
                  sx={{ margin: '1rem 0 2rem 0 !important' }}
                  className={
                    !showMembershipOptions ? 'mainButton' : 'disabledMainButton'
                  }
                  onClick={
                    // user.membership
                    // ? () =>
                    // () => console.log('ASDASD')
                    () => setShowMembershipOptions(true)
                    // : () => setShowMembershipOptions(true)
                  }
                >
                  {user.membership !== 'free' ? 'update' : 'support'}
                </Button>
              </Container>
            </Card>
          </Grid>
        </Grid>
        <BuyMembership
          open={showMembershipOptions}
          handleOpen={setShowMembershipOptions}
          subscriptions={subscriptions}
          customerDetails={userPreferences}
          setShowPayment={setShowPayment}
          showPayment={showPayment}
          // setSelectedSubscription={createCheckoutSession}
          selectedSubscription={selectedSubscription}
          // setShowChangeCancel={setShowChangeCancel}
        />
      </Container>
    </>
  )
}
