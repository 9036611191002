/* eslint-disable */
import './Styling/App.css'
import Amplify, { ServiceWorker } from '@aws-amplify/core'
import Analytics from '@aws-amplify/analytics'
import Auth from '@aws-amplify/auth'
import { useEffect, useState, lazy } from 'react'
import '@aws-amplify/ui-react/styles.css'
import { cognitoConfig, analyticsConfig } from './aws-exports'
import { DetectMobile } from './Common'
import { getPreferences } from './Preferences/GetPreferences'
import { RouteManager } from './Routes'
import { isLocalhost } from './Common/Utils'

Amplify.configure(cognitoConfig)
Analytics.configure(analyticsConfig)

// window.LOG_LEVEL = isLocalhost ? 'DEBUG' : 'NORMAL'

// Analytics.updateEndpoint({
//   attributes: {
//     interests: ['science', 'politics', 'travel']
//     //..
//   },
//   userId: 'UserIdValue',
//   userAttributes: {
//     username: 'ilovethecloud'
//   }
// })

const serviceWorker = new ServiceWorker()
// registeredServiceWorker = serviceWorker.register('/service-worker.js', '/')

const App = () => {
  const deviceType = DetectMobile() ? 'mobile' : 'desktop'
  const [user, setUser] = useState({ username: null })

  useEffect(() => {
    const handleLoad = async () => {
      const prefs = await getPreferences()
      const user = await Auth.currentSession()
      setUser(prefs)
      Analytics.updateEndpoint({
        userId: user.idToken.payload['custom:universal_id']
        // userAttributes: {
        //   username: user.idToken.payload['cognito:username']
        // }
      })
      console.log(
        'endpointId',
        Analytics.getPluggable('AWSPinpoint')._config.endpointId
      )
    }
    handleLoad()
    /**
     * Listen for incoming Push events
     */

    addEventListener('push', (event) => {
      var data = {}
      console.log('[Service Worker] Push Received.')
      console.log(`[Service Worker] Push had this data: "${event.data.text()}"`)

      // if (!(self.Notification && self.Notification.permission === 'granted'))
      //   return

      // if (event.data) data = event.data.json()

      // // Customize the UI for the message box
      // var title = data.title || 'Web Push Notification'
      // var message = data.message || 'New Push Notification Received'
      // var icon = 'images/notification-icon.png'
      // var badge = 'images/notification-badge.png'
      // var options = {
      //   body: message,
      //   icon: icon,
      //   badge: badge
      // }

      // event.waitUntil(self.registration.showNotification(title, options))
    })
  }, [])

  return <RouteManager user={user} setUser={setUser} />
}

export default App
