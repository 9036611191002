const jsesc = require('jsesc')

export const Encoder = (text) => {
  const encoded_string = jsesc(text, {
    escapeEverything: true
  })
  // console.log("tesmp", tesmp)
  // var encoded_string = String.fromCharCode(parseInt(text,16))
  // console.log("encoded_string", encoded_string)
  return encoded_string
}

export const Normaliser = (selectedLanguage, text) => {
  switch (selectedLanguage) {
    case 'arabic':
      return normalizeArabicText(text)
    case 'russian':
      return normalizeRussianText(text)
    default:
      var defaultText = normalizeArabicText(text)
      return defaultText
  }
}

const normalizeArabicText = function (text) {
  // remove special characters
  text = text.replace(
    /([^\u0621-\u063A\u0641-\u064A\u0660-\u0669a-zA-Z 0-9])/g,
    ''
  )

  // normalize Arabic
  text = text.replace(/(آ|إ|أ)/g, 'ا')
  text = text.replace(/(ة)/g, 'ه')
  text = text.replace(/(ئ|ؤ)/g, 'ء')
  text = text.replace(/(ى)/g, 'ي')

  // convert arabic numerals to english counterparts.
  const starter = 0x660
  for (let i = 0; i < 10; i++) {
    text.replace(
      String.fromCharCode(starter + i),
      String.fromCharCode(48 + i)
    )
  }

  return text
}

const normalizeRussianText = function (text) {
  text = text.normalize('NFD').replace(/\p{Diacritic}/gu, '')

  return text
}

export default Normaliser
