import { CircularProgress, Container } from '@mui/material'

const LoadingPage = () => {
  return (
    <Container className="fullPageLoader">
      <CircularProgress />
    </Container>
  )
}

export default LoadingPage
